import Link from 'next/link'

const Footer = () => {
  return (
    <footer className="footer-bg">
      <section className="copyright">
        <div className="footer-half">
          <div className="footer-elem">
            <a href="https://www.spheraeng.com/"> <p className='description'>&copy; {new Date().getFullYear().toString()} by Sphera</p> </a>
          </div>
          <div className="link-wp">
            <Link href="#">
              <p className='description'>| Legal Notice</p>
            </Link>
          </div>
          <div className="link-wp">
            <Link href="#">
              <p className='description'>| Contact Us</p>
            </Link>
          </div>
        </div>
        <div className="footer-half">
          <p></p>
          <div className='logo-wp'>
            <Link href="#">
              <img src="/shared/img/sika-white-building-trust.svg" alt="logo" />
            </Link>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
