"use client"
export const dynamic = 'force-dynamic';

import { useEffect } from 'react';
import Footer from '@/components/shared/Footer'
import Header from '@/components/shared/Header'
import { useSession, signIn } from "next-auth/react";
import { useRouter } from 'next/navigation'

console.error('[PAGE] Loading portal page');
console.log('[PAGE] Loading portal page');

function localDebuggingEnabled() {
  return (process.env.NEXT_PUBLIC_ENABLE_LOCAL_DEBUGGING) && (process.env.NEXT_PUBLIC_ENABLE_LOCAL_DEBUGGING == 'true')
}

function CallToActionCarboDur({ href }) {

  const { data: session } = useSession();
  const router = useRouter()

  // Function to handle the click event
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of the link

    if (session) window.location.href = href;

    if ((!session) && localDebuggingEnabled()) {
      signIn('keycloak').then(() => {
        if (session) window.location.href = href;
      });
    }

    if ((!session) && (!localDebuggingEnabled()) )
      router.push(process.env.NEXT_PUBLIC_SALESFORCE_CARBODUR)
/*
    // Check if the user is authenticated
    if (!session) {
      // If not authenticated, trigger login with Keycloak as the provider
      signIn('keycloak').then(() => {
        if (session) window.location.href = href;
      });
    } else {
      // If authenticated, redirect to the href URL
      window.location.href = href;
    }*/

  };

  return (
    <>
      <div className="card g-centered" id='carbodurCard'>
        <img src="/shared/img/carbodur.jpg" alt="carbodur" className='card-img object-cover w-full h-full' />
        <div className="arrow">
          <p className='description g-centered text-white h-100'>New Release</p>
        </div>
        <div className="innerCard">
          <div className="app-feat-wp">
            <div className='feat-wp'><img src="/shared/img/confinement.png" alt="confinement" className='object-cover' /></div>
            <div className='feat-wp'><img src="/shared/img/flexural.png" alt="flexural" className='object-cover' /></div>
            <div className='feat-wp'><img src="/shared/img/shear.png" alt="shear" className='object-cover' /></div>
            <div className='feat-wp'><img src="/shared/img/beam.png" alt="beam" className='object-cover' /></div>
          </div>
          <div>
            <h3 className='title-card mt-8'>Sika® CarboDur®</h3>
            <p className='desc-card mt-4'>Design FRP strengtheings of reinforced concrete elements.</p>
          </div>
          <div className="launch-wp mt-8">
            <div>
              <a onClick={handleClick}><button className='cta-card'>Launch App</button></a>
            </div>
            <div className='g-centered'>
              <img src="/shared/img/sphera.png" alt="sphera" className='object-cover' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


function CallToActionFiber({ href }) {

  const { data: session } = useSession();
  const router = useRouter()

  // Function to handle the click event
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of the link

    if (session) window.location.href = href;

    if ((!session) && localDebuggingEnabled()) {
      signIn('keycloak').then(() => {
        if (session) window.location.href = href;
      });
    }

    if ((!session) && (!localDebuggingEnabled()) )
      router.push(process.env.NEXT_PUBLIC_SALESFORCE_CARBODUR)
/*
    // Check if the user is authenticated
    if (!session) {
      // If not authenticated, trigger login with Keycloak as the provider
      signIn('keycloak').then(() => {
        if (session) window.location.href = href;
      });
    } else {
      // If authenticated, redirect to the href URL
      window.location.href = href;
    }
*/
  };


  return (
    <>
      <div className="card g-centered" id='carbodurCard'>
        <img src="/shared/img/sikafiber.png" alt="sikafiber" className='card-img object-cover w-full h-full' />
        <div className="arrow">
          <p className='description g-centered text-white h-100'>New Release</p>
        </div>
        <div className="innerCard">
          <div className="app-feat-wp">
            <div className='feat-wp'><img src="/shared/img/slab.png" alt="slab" className='object-cover' /></div>
          </div>
          <div>
            <h3 className='title-card mt-8'>SikaFiber®</h3>
            <p className='desc-card mt-4'>Design fiber reinforced concrete slabs on ground.</p>
          </div>
          <div className="launch-wp mt-8">
            <div>
              <a onClick={handleClick}><button className='cta-card'>Launch App</button></a>
            </div>
            <div className='g-centered'>
              <img src="/shared/img/sphera.png" alt="sphera" className='object-cover' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default function Home() {

  useEffect(() => {
    console.error('[USE_EFFECT] Home component');
    console.log('[USE_EFFECT] Home component');
  }, []);

  return (
    <div className="flex flex-col flex-grow min-h-screen bg-gray-100">

      <div className="bg-supernova-500">
        <Header />
      </div>

      <main className="place-self-center px-16 py-4 bg-gray-100">
        <div className='hero-section'>
          <div className="title-app-wp">
            <h3>Sika launches two new Apps. Explore below.</h3>
          </div>
        </div>
        <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-4 mt-8 news-wp'>
          <div className='news'>
            <div className="arrow">
              <p className='description g-centered text-white h-100'>New Release</p>
            </div>
            <b>CarboDur App for FRP strengthening</b>
          </div>
          <div className='news'>
            <div className="arrow">
              <p className='description g-centered text-white h-100'>New Release</p>
            </div>
            <b>SikaFiber App for FRC slabs on ground</b>
          </div>
          <div className='news'>
            <b>Sika softwares are available at this page</b>
          </div>
        </div>

        <section className="grid xl:grid-cols-2 gap-4 mt-8 card-wp">
          <CallToActionCarboDur href="/carbodur/home" />
          <CallToActionFiber href="/sikafiber/home" />
        </section>
      </main>

      <div className='mt-10vh'>
        <Footer />
      </div>
    </div>
  )
}