// components/shared/Header.jsx
"use client";
import Logo from './Logo';
import HeaderLinks from './HeaderLinks';
import SignIn from './SignIn';

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  backgroundColor: '#FFC510', // Correct background color from tailwind.config.js
  fontFamily: 'Klavika Light, sans-serif',
  fontSize: '16px',
  height: '76px' // Ensure a consistent height
};

const headerHalfStyle = {
  display: 'flex',
  alignItems: 'center'
};

const Header = () => (
  <header id='header' style={headerStyle}>
    <div style={headerHalfStyle}>
      <Logo />
      <h5 style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', fontWeight: 100 }}>
        Sika® Software
      </h5>
    </div>
    <div style={headerHalfStyle}>
      <HeaderLinks />
      <SignIn />
    </div>
  </header>
);

export default Header;
