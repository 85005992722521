// components/shared/HeaderLinks.jsx
import Link from 'next/link';

const linkStyle = {
  textDecoration: 'none',
  margin: '0 15px', // Increased margin for better spacing
  color: 'black',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 100 // Ensure lighter font weight
};

const HeaderLinks = () => (
  <>
    <div className="link-wp">
      <Link href="/" style={linkStyle}>
        Contact Us
      </Link>
    </div>
    <div className="link-wp">
      <Link href="/" style={linkStyle}>
        Website
      </Link>
    </div>
  </>
);

export default HeaderLinks;
