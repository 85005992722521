// components/shared/SignIn.jsx
import { useSession, signIn } from "next-auth/react";
import federatedLogout from '@/utils/federatedLogout';
import UserProfile from '@/components/shared/UserProfile';

const linkStyle = {
  textDecoration: 'none',
  margin: '0 15px', // Increased margin for better spacing
  color: 'black',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 100 // Ensure lighter font weight
};

const SignIn = () => {
  const { data: session, status } = useSession();

  return (
    <div className="link-wp" style={{ display: 'flex', alignItems: 'center' }}>
      {status === 'authenticated' ? (
        <>
          <UserProfile session={session} />
          <button className='description ml-2' onClick={federatedLogout} style={linkStyle}>
            Sign Out
          </button>
        </>
      ) : (
          <>
            {/*
              <button className='description mx-1' onClick={() => signIn('keycloak')} style={linkStyle}>
                Sign In
              </button>
            */}
            <button className='description mx-1 border p-2 rounded-lg' onClick={() => signIn('azuread')} style={linkStyle}>
              Sika Employee Login
            </button>
          </>
      )}
    </div>
  );
};

export default SignIn;
