// components/shared/Logo.jsx
import Link from 'next/link';

const Logo = () => (
  <div className='logo-wp' style={{ flexShrink: 0, height: '40px', display: 'flex', alignItems: 'center' }}>
    <Link href="/">
      <img src="/shared/img/logo-light-full.svg" alt="logo" style={{ height: '100%' }} />
    </Link>
  </div>
);

export default Logo;
